import React, { useRef, useEffect, useState } from 'react';
import { ThirdSectionStyled, Card, CardContent, Button, Title } from './ThirdSection.styles';
import { useTranslation } from 'react-i18next';
import squareOne from '../../../assets/landingPage/square_one.png';
import squareTwo from '../../../assets/landingPage/square_two.png';

const ThirdSection: React.FC = () => {
  const { t } = useTranslation();
  const cardRefs = [useRef<HTMLDivElement | null>(null), useRef<HTMLDivElement | null>(null)];
  const titleRef = useRef<HTMLHeadingElement | null>(null);
  const [visible, setVisible] = useState([false, false]);
  const [titleVisible, setTitleVisible] = useState(false);

  useEffect(() => {
    console.log('Card 1 visible:', visible[0]);
    console.log('Card 2 visible:', visible[1]);
  }, [visible]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.target === titleRef.current) {
            setTitleVisible(entry.isIntersecting);
          } else {
            const index = cardRefs.findIndex(ref => ref.current === entry.target);
            if (index !== -1) {
              setVisible((prev) => {
                const newVisible = [...prev];
                newVisible[index] = entry.isIntersecting;
                return newVisible;
              });
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    cardRefs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    if (titleRef.current) {
      observer.observe(titleRef.current);
    }

    return () => {
      cardRefs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });

      if (titleRef.current) {
        observer.unobserve(titleRef.current);
      }
    };
  }, []);

  return (
    <ThirdSectionStyled>
      <Title ref={titleRef} $visible={titleVisible}>
        {t("landingPage.thirdSection.title")}
      </Title>
      <div className="cards">
        <Card ref={cardRefs[0]} $visible={visible[0]} $background={squareOne}>
          <CardContent>
            <p>{t("landingPage.thirdSection.card1.text")}</p>
            <Button>{t("landingPage.thirdSection.card1.button")}</Button>
          </CardContent>
        </Card>
        <Card ref={cardRefs[1]} $visible={visible[1]} $background={squareTwo}>
          <CardContent>
            <p>{t("landingPage.thirdSection.card2.text")}</p>
            <Button>{t("landingPage.thirdSection.card2.button")}</Button>
          </CardContent>
        </Card>
      </div>
    </ThirdSectionStyled>
  );
};

export default ThirdSection;