import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GlobalStyle, Container, ContentWrapper, Navbar, Logo, NavLinks, NavLink, LanguageToggle, Footer, FooterContent, FooterLinks, FooterLink, FooterContact, DownloadButton, MenuToggle, Box } from "./Layout.styles";
import logo from "../../assets/logos/tuyopass.svg";
import EmailForm from "./EmailForm";
import { useLocation } from 'react-router-dom';

interface LayoutProps {
  children: React.ReactNode;
  setIsEmailFormOpen?: (isOpen: boolean) => void;
}

const Layout: React.FC<LayoutProps> = ({ children}) => {
  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const [isEmailFormOpen, setIsEmailFormOpen] = useState(false);
  const location = useLocation();

  const toggleLanguage = () => {
    const nextLanguage = i18n.language === 'en' ? 'fr' : i18n.language === 'fr' ? 'es' : 'en';
    i18n.changeLanguage(nextLanguage);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsTop(window.scrollY === 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    // Check if there's a hash in the URL
    if (location.hash) {
      // Remove the # from the hash
      const element = document.getElementById(location.hash.slice(1));
      if (element) {
        // Wait a bit for the page to render and then scroll
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    }
  }, [location]);

  const isMobile = window.innerWidth <= 768;

  return (
    <>
      <GlobalStyle />
      <Container>
        <Navbar style={{
          backgroundColor: isTop && !isEmailFormOpen ? 'rgba(255, 255, 255, 0.8)' : 'white',
          margin: isMobile
          ? '10px 10px'
          : isTop
          ? '20px 160px 10px 160px'
          : '20px 120px 10px 120px',
        transition: 'background-color 0.3s ease, margin 0.3s ease',
      }}>
          <Box>
            <Logo to="/">
              <img src={logo} alt="TuyoPass Logo" />
            </Logo>
            <MenuToggle onClick={toggleMenu}>
              ☰
            </MenuToggle>
          </Box>
          <NavLinks className={menuOpen ? "open" : ""}>
            <NavLink to="/#about">{t("landingPage.nav.about")}</NavLink>
            <NavLink
              to="/#contact"
              onClick={(e) => {
                e.preventDefault();
                setIsEmailFormOpen(true);
                toggleMenu();
              }}
            >
              {t("landingPage.nav.contact")}
            </NavLink>
            <NavLink to="/#faq">{t("landingPage.nav.faq")}</NavLink>
            <DownloadButton>{t("landingPage.nav.download")}</DownloadButton>
            <LanguageToggle onClick={toggleLanguage}>
              {i18n.language.toUpperCase()}
            </LanguageToggle>
          </NavLinks>
        </Navbar>
        <ContentWrapper>
          {children}
        </ContentWrapper>
        <Footer>
          <FooterContent>
            <FooterLinks>
              <FooterLink to="/privacy-policy">{t("landingPage.footer.privacyPolicy")}</FooterLink>
              {/* <FooterLink to="/terms-conditions">{t("landingPage.footer.termsConditions")}</FooterLink> */}
            </FooterLinks>
            <FooterContact>hi@tuyopass.com</FooterContact>
          </FooterContent>
        </Footer>
      </Container>
        {isEmailFormOpen && <EmailForm onClose={() => setIsEmailFormOpen(false)} />}
    </>
  );
};

export default Layout;