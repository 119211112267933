import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { landingPage as enLandingPage } from './locales/en/landingPage';
import { privacyPolicy as enPrivacyPolicy } from './locales/en/privacyPolicy';
import { termsAndConditions as enTermsAndConditions } from './locales/en/termsAndConditions';

import { landingPage as frLandingPage } from './locales/fr/landingPage';
import { privacyPolicy as frPrivacyPolicy } from './locales/fr/privacyPolicy';
import { termsAndConditions as frTermsAndConditions } from './locales/fr/termsAndConditions';

import { landingPage as esLandingPage } from './locales/es/landingPage';
import { privacyPolicy as esPrivacyPolicy } from './locales/es/privacyPolicy';
import { termsAndConditions as esTermsAndConditions } from './locales/es/termsAndConditions';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          landingPage: enLandingPage,
          privacyPolicy: enPrivacyPolicy,
          termsAndConditions: enTermsAndConditions
        }
      },
      fr: {
        translation: {
          landingPage: frLandingPage,
          privacyPolicy: frPrivacyPolicy,
          termsAndConditions: frTermsAndConditions
        }
      },
      es: {
        translation: {
          landingPage: esLandingPage,
          privacyPolicy: esPrivacyPolicy,
          termsAndConditions: esTermsAndConditions
        }
      },
    },
    lng: 'es', // Default language
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
