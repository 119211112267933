import styled, { css, keyframes } from 'styled-components';
import { colors } from '../../../styles/colors';

const growBounce = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  60% {
    transform: scale(1.05);
    opacity: 1;
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
`;

const shrink = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.4);
  }
`;

export const ThirdSectionStyled = styled.section`
  background-color: ${colors.blue1};
  padding: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;

  .cards {
    display: flex;
    justify-content: center;
    gap: 80px;
    flex-wrap: wrap;
  }
`;

export const Title = styled.h2<{ $visible: boolean }>`
  color: ${colors.white};
  margin-bottom: 60px;
  opacity: 0;
  transform: scale(0);
    font-size: 2.5em;
    font-weight: bold;

    @media (max-width: 768px) {
      font-size: 30px;
      text-align: center;
    }

  ${({ $visible }) =>
    $visible &&
  css`
  transition: opacity 0.2s ease, transform 0.2s ease;
  opacity: 1;
  transform: scale(1);
`}
`;

export const Card = styled.div<{ $background: string; $visible: boolean }>`
  ${({ $background }) => css`
    background-image: url(${$background});
  `}
  display: grid;
  place-items: center;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  overflow: hidden;
  flex: 1 1 45%;
  max-width: 600px;
  position: relative;
  height: 600px;
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.5s ease, transform 0.5s ease;

  ${({ $visible }) =>
    $visible
      ? css`
          animation: ${growBounce} 0.8s forwards;
          opacity: 1;
          transform: scale(1);
        `
      : css`
          animation: ${shrink} 0.2s forwards;
        `}

  @media (max-width: 768px) {
    flex: 1 1 100%;
    height: 300px;
  }
`;

export const CardContent = styled.div`
  padding: 20px;
  font-size: 25px;
  color: ${colors.white};
  text-align: center;

  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

export const Button = styled.button`
  background-color: ${colors.orange1};
  color: ${colors.white};
  border: none;
  font-size: 20px;
  font-weight: bold;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;

  @media (max-width: 768px) {
    font-size: 15px;
  }
`;
