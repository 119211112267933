export const landingPage = {
  nav: {
    about: "À propos",
    contact: "Contact",
    faq: "FAQ",
    download: "Télécharger"
  },
  comingSoon: "Bientôt disponible",
  hero: {
    title1: "Qu'attendez-vous pour commencer à",
    title2: "vivre les expériences",
    title3: "dont vous avez toujours rêvé ?",
    subtitle: "Abonnez-vous à TuyoPass et profitez de réductions exclusives sur un large éventail d'expériences.",
    cta: "Rejoignez la liste d'attente"
  },
  secondSection: {
    title1: "Faites",
    highlight1: "plus",
    title2: "en dépensant",
    highlight2: "moins",
    description: "Débloquez un monde d'économies et d'expériences avec TuyoPass. Votre passeport pour l'aventure, l'indulgence et une vie intelligente."
  },
  about: {
    title: "À propos de TuyoPass",
    text: "TuyoPass est votre clé pour débloquer des réductions exclusives sur un large éventail d'expériences. Des cafés chaleureux aux aventures palpitantes, nous sommes là pour alimenter vos passions sans vous ruiner. Avec TuyoPass, chaque jour devient une opportunité d'explorer, de profiter et de vivre pleinement, tout en gardant votre portefeuille heureux."
  },
  mission: {
    title: "Notre Mission",
    text: "Nous sommes en mission pour démocratiser les expériences. En connectant des abonnés avisés à des réductions exclusives, nous ne vous faisons pas seulement économiser de l'argent, nous ouvrons des portes à de nouvelles aventures, saveurs et souvenirs. Notre objectif ? Vous permettre de faire plus de ce que vous aimez, d'essayer de nouvelles choses et de vivre sans limites."
  },
  vision: {
    title: "Notre Vision",
    text: "Imaginez un monde où les contraintes budgétaires ne vous empêchent pas de profiter des joies de la vie. C'est le monde que nous créons avec TuyoPass. Nous envisageons une communauté d'aventuriers, de gourmets, d'enthousiastes du fitness et d'amateurs de culture, tous habilités à poursuivre leurs passions plus fréquemment et de manière plus abordable."
  },
  values: {
    title: "Nos Valeurs",
    text: "Aventure : Nous croyons en dire 'oui' à de nouvelles expériences.\nAccessibilité : Les grandes expériences devraient être à la portée de tous.\nCommunauté : Nous construisons un réseau de chercheurs d'expériences partageant les mêmes idées.\nValeur : Nous nous engageons à vous offrir des offres et des réductions imbattables."
  },
  footer: {
    privacyPolicy: "Politique de confidentialité",
    termsConditions: "Conditions générales",
    contact: "Contact"
  },
  thirdSection: {
    title: "Qu'attendez-vous ?",
    card1: {
      text: "Vous voulez que nos utilisateurs découvrent votre entreprise ? Contactez-nous ! Nous serions ravis que vous rejoigniez la TuyoFamille.",
      button: "Contactez-nous"
    },
    card2: {
      text: "Je veux tirer le meilleur parti de chaque expérience disponible sur TuyoPass.",
      button: "Télécharger"
    }
  },
  fourthSection: {
    title: "Questions Fréquentes",
    faq1: {
      question: "Qu'est-ce que TuyoPass ?",
      answer: "TuyoPass est une plateforme d'abonnement qui vous offre des réductions exclusives sur une grande variété d'expériences, des restaurants et cafés aux activités d'aventure et de bien-être."
    },
    faq2: {
      question: "Comment ça marche ?",
      answer: "En vous abonnant à TuyoPass, vous obtenez un accès immédiat à des réductions exclusives dans notre réseau d'établissements partenaires. Il vous suffit de présenter votre TuyoPass dans l'établissement pour profiter des avantages."
    },
    faq3: {
      question: "Combien coûte l'abonnement ?",
      answer: "Nous sommes actuellement en phase de lancement. Rejoignez notre liste d'attente pour être parmi les premiers à connaître nos tarifs spéciaux de lancement."
    },
    faq4: {
      question: "Où puis-je utiliser TuyoPass ?",
      answer: "TuyoPass peut être utilisé dans tous nos établissements partenaires. Nous élargissons constamment notre réseau de partenaires pour vous offrir plus d'options."
    }
  }
};