import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import Hero from "./components/Hero";
import SecondSection from "./components/SecondSection";
import ThirdSection from "./components/ThirdSection";
import FourthSection from "./components/FourthSection";
const LandingPage: React.FC = () => {
  const [backgroundScale, setBackgroundScale] = useState(1);
  const [borderRadius, setBorderRadius] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const newScale = Math.max(1 - scrollY / 2000, 0.9);
      const newRadius = scrollY > 0 ? 40 : 0;
      setBackgroundScale(newScale);
      setBorderRadius(newRadius);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Layout>
      <Hero backgroundScale={backgroundScale} borderRadius={borderRadius} />
      <div id="about">
      <SecondSection />
      </div>
      <ThirdSection />
      <div id="faq">
      <FourthSection />
      </div>
      {/* Other sections */}
    </Layout>
  );
};

export default LandingPage;