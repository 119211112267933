import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { 
  FourthSectionStyled, 
  Title, 
  FAQContainer, 
  FAQItem, 
  Question,
  Answer 
} from './FourthSection.styles';
import { colors } from '../../../styles/colors';

const FourthSection: React.FC = () => {
  const { t } = useTranslation();
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const faqs = [
    {
      question: t('landingPage.fourthSection.faq1.question'),
      answer: t('landingPage.fourthSection.faq1.answer'),
    },
    {
      question: t('landingPage.fourthSection.faq2.question'),
      answer: t('landingPage.fourthSection.faq2.answer'),
    },
    {
      question: t('landingPage.fourthSection.faq3.question'),
      answer: t('landingPage.fourthSection.faq3.answer'),
    },
    {
      question: t('landingPage.fourthSection.faq4.question'),
      answer: t('landingPage.fourthSection.faq4.answer'),
    },
  ];

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <FourthSectionStyled>
      <Title>{t('landingPage.fourthSection.title')}</Title>
      <FAQContainer>
        {faqs.map((faq, index) => (
          <FAQItem key={index} $isOpen={openIndex === index}>
            <Question onClick={() => handleToggle(index)}>
              {faq.question}
              <span style={{ color: colors.orange1 }}>{openIndex === index ? '-' : '+'}</span>
            </Question>
            <Answer $isOpen={openIndex === index}>
              {faq.answer}
            </Answer>
          </FAQItem>
        ))}
      </FAQContainer>
    </FourthSectionStyled>
  );
};

export default FourthSection;
