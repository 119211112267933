import styled, { createGlobalStyle } from "styled-components";
import { Link } from "react-router-dom";
import { colors } from "../../styles/colors";

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600&display=swap');

  * {
    box-sizing: border-box;
  }

  body {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    min-height: 100vh;
  }
`;

export const Container = styled.div`
  background-color: ${colors.blue1};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
`;

export const ContentWrapper = styled.div`
  flex: 1;
`;

export const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5%;
  margin: 20px 120px 10px 120px;
  background-color: #ffffff;
  border-radius: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    margin: 10px auto;
    border-radius: 20px;
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Logo = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  text-decoration: none;
  color: #000;

  img {
    height: 50px;

    @media (max-width: 1350px) {
      height: 50px; /* Adjust as needed for smaller screens */
    }
  }
`;


export const MenuToggle = styled.button`
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;

  @media (max-width: 1350px) {
    display: block;
  }
`;

export const NavLinks = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  @media (max-width: 1350px) {
    display: none;
    padding-top: 20px;
    flex-direction: column;
    width: 100%;

    &.open {
      display: flex;
    }
  }
`;

export const NavLink = styled(Link)`
  text-decoration: none;
  color: #000;
  white-space: nowrap; /* Prevent line breaks */
  font-weight: 500; /* Slightly bolder text */
  padding: 10px 15px; /* Padding for clickable area */
  border-radius: 10px; /* Rounded corners for links */
  transition: background-color 0.3s;

  &:hover {
    background-color: #f0f0f0; /* Light background on hover */
  }
`;

export const LanguageToggle = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #000;
`;

export const DownloadButton = styled.button`
  background-color: ${colors.orange1}; /* Button color */
  color: white; /* Text color */
  border: none;
  border-radius: 20px; /* Rounded corners */
  padding: 10px 20px; /* Padding */
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e65c2f; /* Darker shade on hover */
  }
`;

export const Footer = styled.footer`
  background-color: #f8f8f8;
  padding: 20px 0;
  border-top: 1px solid #e7e7e7;
  width: 100%;
`;

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 5%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`;

export const FooterLink = styled(Link)`
  color: #333;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`;

export const FooterContact = styled.p`
  margin: 0;
  font-size: 14px;
  color: #666;
`;