import styled, { css } from 'styled-components';
import { colors } from '../../../styles/colors';

export const FourthSectionStyled = styled.section`
  padding: 60px 40px;
  background-color: ${colors.blue1};
`;

export const Title = styled.h2`
  font-size: 3em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
  color: ${colors.white};
  -webkit-text-fill-color: initial;
`;

export const FAQContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FAQItem = styled.div<{ $isOpen: boolean }>`
  border-radius: 12px;
  background-color: ${colors.white};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: all 0.3s ease;
  
  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
  }
`;

export const Question = styled.div`
  padding: 20px;
  font-size: 1.1em;
  font-weight: 800;
  cursor: pointer;
  color: ${colors.blue1};
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 1.5em;
    font-weight: 300;
  }
`;

export const Answer = styled.div<{ $isOpen: boolean }>`
  padding: 0 20px;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
  opacity: 0;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      padding: 0 20px 20px;
      max-height: 500px;
      opacity: 1;
    `}
`;
