import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondSection as SecondSectionStyled, TextContent, ImageContent, StoreButtons } from '../LandingPage.styles';
import appScreenshot from '../../../assets/landingPage/appscreen.png';
import appStore from '../../../assets/landingPage/app_store.svg';
import androidStore from '../../../assets/landingPage/app_store.svg';
import { colors } from '../../../styles/colors';

const SecondSection: React.FC = () => {
  const { t } = useTranslation();
  const textRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    setHasAnimated(false);
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    handleResize(); // Set initial visibility based on screen size

    const observer = new IntersectionObserver(
      ([entry]) => {
        console.log("isVisible", isVisible)
        console.log("hasAnimated", hasAnimated)
        console.log("entry.isIntersecting", entry.isIntersecting)
        if (window.innerWidth > 768 && entry.isIntersecting && !hasAnimated) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => {
      if (textRef.current) {
        observer.unobserve(textRef.current);
      }
    };
  }, [hasAnimated]); // Add hasAnimated to the dependency array

  return (
    <SecondSectionStyled>
      <TextContent
        ref={textRef}
        style={{
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 3s ease',
        }}
      >
        <h1 style={{ color: colors.white }}>
          {t("landingPage.secondSection.title1")}{" "}
          <span style={{ color: colors.orange1 }}>
            {t("landingPage.secondSection.highlight1")}
          </span>,{" "}
          {t("landingPage.secondSection.title2")} <span style={{ color: colors.orange1 }}>{t("landingPage.secondSection.highlight2")}</span>...
        </h1>
        <p style={{ color: colors.white }}>
          {t("landingPage.secondSection.description")}
        </p>
        <StoreButtons>
          <img src={appStore} alt="Download on the App Store" />
          <img src={androidStore} alt="Get it on Google Play" />
        </StoreButtons>
      </TextContent>
      <ImageContent
        ref={imageRef}
        style={{
          transform: isVisible ? 'translateX(0)' : 'translateX(95%)',
          transition: 'transform 1.5s ease',
        }}
      >
        <img src={appScreenshot} alt="App Screenshot" />
      </ImageContent>
    </SecondSectionStyled>
  );
};

export default SecondSection;