import styled from "styled-components";
import { colors } from "../../styles/colors";

export const Hero = styled.div`
  position: relative;
  background-color: ${colors.blue1};
  background-image:
    url(${require('../../assets/landingPage/landingpage.png')});
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  border-radius: 40px;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  padding: 0;
  margin: 0;
  overflow: hidden;
`;

export const HeroTitle = styled.h1`
  position: relative;
  z-index: 1;
  font-size: 50px;
  font-weight: 800;
  max-width: 800px;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 24px; // Adjust font size for smaller screens
  }
`;

export const HeroSubtitle = styled.p`
  font-size: 24px;
  font-weight: 400;
  max-width: 100%;
  margin-bottom: 30px;
`;

export const Section = styled.section`
  margin-bottom: 40px; /* Space between sections */

  h2 {
    margin-bottom: 10px; /* Space between header and paragraph */
  }
`;

export const ComingSoon = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #333;
  position: relative;
  overflow: hidden;
  border: 3px solid transparent;
  animation: borderGlow 5s infinite alternate,
    boxShadowGlow 5s infinite alternate;

  @keyframes borderGlow {
    0% {
      border-color: rgba(173, 216, 230, 0.5); /* Light Blue */
    }
    50% {
      border-color: rgba(224, 255, 255, 0.5); /* Light Cyan */
    }
    100% {
      border-color: rgba(173, 216, 230, 0.5); /* Light Blue */
    }
  }

  @keyframes boxShadowGlow {
    0% {
      box-shadow: 0 0 10px rgba(173, 216, 230, 0.2); /* Light Blue */
    }
    50% {
      box-shadow: 0 0 10px rgba(224, 255, 255, 0.2); /* Light Cyan */
    }
    100% {
      box-shadow: 0 0 10px rgba(173, 216, 230, 0.2); /* Light Blue */
    }
  }
`;

export const SecondSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  background-color: ${colors.blue1};
  color: black;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
`;

export const StoreButtons = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;

  img {
    width: 150px; /* Adjust size as needed */
    height: auto;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    justify-content: center;
    width: 120px;
  }
`;

export const TextContent = styled.div`
  max-width: 50%;
  font-size: 1.5em;
  line-height: 1.4;

  h1 {
    font-size: 2.5em;
    font-weight: bold;

    @media (max-width: 768px) {
      font-size: 30px;
      text-align: center;
    }
  }

  span {
    color: ${colors.orange1};
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100%;

    p {
      font-size: 16px;
    }
  }
`;

export const ImageContent = styled.div`
  max-width: 40%;
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {
    justify-content: center;
    img {
      margin: 10px 50px 0px 0px;
      width: auto;
      height: 600px;
    }
  }
`;