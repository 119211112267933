import React from 'react';
import { useTranslation } from 'react-i18next';
import { Hero as HeroStyled, HeroTitle } from '../LandingPage.styles';
import { colors } from '../../../styles/colors';

const Hero: React.FC<{ backgroundScale: number; borderRadius: number }> = ({ backgroundScale, borderRadius }) => {
  const { t } = useTranslation();

  return (
    <HeroStyled style={{ backgroundRepeat: 'no-repeat', backgroundPosition: 'center', transform: `scale(${backgroundScale})`, borderRadius: `${borderRadius}px` }}>
      <HeroTitle>
        {t("landingPage.hero.title1")}{" "}
        <span style={{ color: colors.orange1 }}>
          {t("landingPage.hero.title2")}
        </span>{" "}
        {t("landingPage.hero.title3")}
      </HeroTitle>
    </HeroStyled>
  );
};

export default Hero;