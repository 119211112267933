import styled, { keyframes } from 'styled-components';
import { colors } from '../../styles/colors';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 998;
  padding: 20px;
  animation: ${fadeIn} 0.3s ease-out;
  overflow-y: auto;
`;

export const FormContainer = styled.div`
  background-color: #1a1d21;
  padding: 40px;
  border-radius: 12px;
  position: relative;
  max-width: 600px;
  width: 100%;
  margin: 80px auto 0;
  animation: ${fadeIn} 0.3s ease-out;
  max-height: 90vh;
  overflow-y: auto;

  /* Custom scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #1a1d21;
  }

  &::-webkit-scrollbar-thumb {
    background: #2a3038;
    border-radius: 4px;
  }

  @media (max-width: 768px) {
    margin: 100px auto 0;
  }
`;

export const Title = styled.h2`
  font-size: 2em;
  color: ${colors.white};
  margin-bottom: 16px;

`;

export const Subtitle = styled.p`
  color: ${colors.white};
  margin-bottom: 32px;
  font-size: 1.1em;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Input = styled.input`
  padding: 16px;
  border-radius: 8px;
  border: none;
  background-color: #2a3038;
  color: ${colors.white};
  font-size: 16px;

  &::placeholder {
    color: #6c757d;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${colors.orange1};
  }
`;

export const TextArea = styled.textarea`
  padding: 16px;
  border-radius: 8px;
  border: none;
  background-color: #2a3038;
  color: ${colors.white};
  font-size: 16px;
  min-height: 120px;
  resize: vertical;

  &::placeholder {
    color: #6c757d;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${colors.orange1};
  }
`;

export const SubmitButton = styled.button`
  padding: 16px;
  border-radius: 999px;
  border: none;
  background: linear-gradient(90deg, ${colors.orange1}, ${colors.orange2});
  color: ${colors.white};
  font-size: 1.1em;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.98);
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: ${colors.white};
  font-size: 1.5em;
  cursor: pointer;
  padding: 8px;
  line-height: 1;

  &:hover {
    opacity: 0.8;
  }
`;