import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Layout from './Layout/Layout';

const Title = styled.h1`
  font-size: 2.5em;
  margin-bottom: 20px;
`;

const Section = styled.section`
  margin-bottom: 30px;
`;

const SectionTitle = styled.h2`
  font-size: 1.8em;
  margin-bottom: 15px;
`;

const Paragraph = styled.p`
  margin-bottom: 15px;
  line-height: 1.6;
`;

const List = styled.ul`
  margin-bottom: 15px;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 5px;
`;

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Title>{t('privacyPolicy.title')}</Title>
      <Paragraph>{t('privacyPolicy.introduction')}</Paragraph>

      <Section>
        <SectionTitle>{t('privacyPolicy.informationCollection.title')}</SectionTitle>
        <Paragraph>{t('privacyPolicy.informationCollection.content')}</Paragraph>
        <List>
          {(t('privacyPolicy.informationCollection.list', { returnObjects: true }) as string[]).map((item, index) => (
            <ListItem key={index}>{item}</ListItem>
          ))}
        </List>
        <Paragraph>{t('privacyPolicy.informationCollection.additionalInfo')}</Paragraph>
      </Section>

      <Section>
        <SectionTitle>{t('privacyPolicy.thirdPartyAccess.title')}</SectionTitle>
        <Paragraph>{t('privacyPolicy.thirdPartyAccess.content')}</Paragraph>
        <List>
          {(t('privacyPolicy.thirdPartyAccess.services', { returnObjects: true }) as string[]).map((item, index) => (
            <ListItem key={index}>{item}</ListItem>
          ))}
        </List>
        <Paragraph>{t('privacyPolicy.thirdPartyAccess.disclosure')}</Paragraph>
      </Section>

      <Section>
        <SectionTitle>{t('privacyPolicy.optOutRights.title')}</SectionTitle>
        <Paragraph>{t('privacyPolicy.optOutRights.content')}</Paragraph>
      </Section>

      <Section>
        <SectionTitle>{t('privacyPolicy.dataRetention.title')}</SectionTitle>
        <Paragraph>{t('privacyPolicy.dataRetention.content')}</Paragraph>
      </Section>

      <Section>
        <SectionTitle>{t('privacyPolicy.children.title')}</SectionTitle>
        <Paragraph>{t('privacyPolicy.children.content')}</Paragraph>
      </Section>

      <Section>
        <SectionTitle>{t('privacyPolicy.security.title')}</SectionTitle>
        <Paragraph>{t('privacyPolicy.security.content')}</Paragraph>
      </Section>

      <Section>
        <SectionTitle>{t('privacyPolicy.changes.title')}</SectionTitle>
        <Paragraph>{t('privacyPolicy.changes.content')}</Paragraph>
        <Paragraph>{t('privacyPolicy.changes.effectiveDate')}</Paragraph>
      </Section>

      <Section>
        <SectionTitle>{t('privacyPolicy.consent.title')}</SectionTitle>
        <Paragraph>{t('privacyPolicy.consent.content')}</Paragraph>
      </Section>

      <Section>
        <SectionTitle>{t('privacyPolicy.contact.title')}</SectionTitle>
        <Paragraph>{t('privacyPolicy.contact.content')}</Paragraph>
      </Section>
    </Layout>
  );
};

export default PrivacyPolicy;
