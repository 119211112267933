import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormContainer,
  Title,
  Subtitle,
  StyledForm,
  Input,
  TextArea,
  SubmitButton,
  CloseButton,
  Overlay
} from './EmailForm.styles';

interface EmailFormProps {
  onClose?: () => void;
}

const EmailForm: React.FC<EmailFormProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Add your form submission logic here
    console.log('Form submitted:', formData);
    // Reset form after submission
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      message: ''
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <Overlay>
    <FormContainer>
      {onClose && (
        <CloseButton onClick={onClose}>
          ✕
        </CloseButton>
      )}
      <Title>
        {t('landingPage.emailForm.title', 'Estás listo para comenzar tu aventura con TuyoPass?')}
      </Title>
      <Subtitle>
        {t('landingPage.emailForm.subtitle', 'Nos pondremos en contacto contigo lo mas pronto posible')}
      </Subtitle>
      
      <StyledForm onSubmit={handleSubmit}>
        <Input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          placeholder={t('landingPage.emailForm.firstName', 'Nombre')}
          required
        />
        
        <Input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          placeholder={t('landingPage.emailForm.lastName', 'Apellido')}
          required
        />
        
        <Input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder={t('landingPage.emailForm.email', 'Email')}
          required
        />
        
        <TextArea
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder={t('landingPage.emailForm.message', 'Como podemos ayudarte?')}
          required
        />
        
        <SubmitButton type="submit">
          {t('landingPage.emailForm.submit', 'Contáctanos')}
        </SubmitButton>
      </StyledForm>
    </FormContainer>
    </Overlay>
  );
};

export default EmailForm;