export const landingPage = {
  nav: {
    about: "About",
    contact: "Contact",
    faq: "FAQ",
    download: "Download"
  },
  comingSoon: "Coming Soon",
  hero: {
    title1: "What are you waiting for to start",
    title2: "living the experiences",
    title3: "you've always dreamed of?",
    subtitle: "Subscribe to TuyoPass and enjoy exclusive discounts on a wide range of experiences.",
    cta: "Join the waitlist"
  },
  secondSection: {
    title1: "Do",
    highlight1: "more",
    title2: "spending",
    highlight2: "less",
    description: "Unlock a world of savings and experiences with TuyoPass. Your passport to adventure, indulgence, and smart living."
  },
  about: {
    title: "About TuyoPass",
    text: "TuyoPass is your key to unlocking exclusive discounts on a wide range of experiences. From cozy cafes to thrilling adventures, we're here to fuel your passions without breaking the bank. With TuyoPass, every day becomes an opportunity to explore, enjoy, and live life to the fullest, all while keeping your wallet happy."
  },
  mission: {
    title: "Our Mission",
    text: "We are on a mission to democratize experiences. By connecting savvy subscribers with exclusive discounts, we're not just saving you money, we're opening doors to new adventures, flavors, and memories. Our goal? To empower you to do more of what you love, try new things, and live life without limits."
  },
  vision: {
    title: "Our Vision",
    text: "Imagine a world where budget constraints don't stop you from experiencing life's joys. That's the world we're creating with TuyoPass. We envision a community of adventurers, food lovers, fitness enthusiasts, and culture aficionados, all empowered to pursue their passions more frequently and affordably."
  },
  values: {
    title: "Our Values",
    text: "Adventure: We believe in saying 'yes' to new experiences.\nAccessibility: Great experiences should be within everyone's reach.\nCommunity: We're building a network of like-minded experience seekers.\nValue: We're committed to bringing you unbeatable deals and discounts."
  },
  footer: {
    privacyPolicy: "Privacy Policy",
    termsConditions: "Terms and Conditions",
    contact: "Contact"
  },
  thirdSection: {
    title: "What are you waiting for?",
    card1: {
      text: "Want our users to discover your business? Contact us! We'd love for you to join the TuyoFamily.",
      button: "Contact Us"
    },
    card2: {
      text: "I want to make the most of every experience available on TuyoPass.",
      button: "Download"
    }
  },
  fourthSection: {
    title: "Frequently Asked Questions",
    faq1: {
      question: "What is TuyoPass?",
      answer: "TuyoPass is a subscription platform that offers you exclusive discounts on a wide variety of experiences, from restaurants and cafes to adventure activities and wellness."
    },
    faq2: {
      question: "How does it work?",
      answer: "When you subscribe to TuyoPass, you get immediate access to exclusive discounts across our network of partner establishments. Simply show your TuyoPass at the venue to enjoy the benefits."
    },
    faq3: {
      question: "How much does the subscription cost?",
      answer: "We are currently in our launch phase. Join our waitlist to be among the first to know about our special launch pricing."
    },
    faq4: {
      question: "Where can I use TuyoPass?",
      answer: "TuyoPass can be used at all our partner establishments. We're constantly expanding our network of partners to offer you more options."
    }
  }
};