import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Layout from './Layout/Layout';

const Title = styled.h1`
  font-size: 2.5em;
  margin-bottom: 20px;
`;

const Section = styled.section`
  margin-bottom: 30px;
`;

const SectionTitle = styled.h2`
  font-size: 1.8em;
  margin-bottom: 15px;
`;

const Paragraph = styled.p`
  margin-bottom: 15px;
  line-height: 1.6;
`;

const TermsAndConditions: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Title>{t('termsAndConditions.title')}</Title>
      <Section>
        <SectionTitle>{t('termsAndConditions.acceptance.title')}</SectionTitle>
        <Paragraph>{t('termsAndConditions.acceptance.content')}</Paragraph>
      </Section>
      <Section>
        <SectionTitle>{t('termsAndConditions.services.title')}</SectionTitle>
        <Paragraph>{t('termsAndConditions.services.content')}</Paragraph>
      </Section>
      <Section>
        <SectionTitle>{t('termsAndConditions.userObligations.title')}</SectionTitle>
        <Paragraph>{t('termsAndConditions.userObligations.content')}</Paragraph>
      </Section>
      <Section>
        <SectionTitle>{t('termsAndConditions.intellectualProperty.title')}</SectionTitle>
        <Paragraph>{t('termsAndConditions.intellectualProperty.content')}</Paragraph>
      </Section>
      <Section>
        <SectionTitle>{t('termsAndConditions.liability.title')}</SectionTitle>
        <Paragraph>{t('termsAndConditions.liability.content')}</Paragraph>
      </Section>
      <Section>
        <SectionTitle>{t('termsAndConditions.termination.title')}</SectionTitle>
        <Paragraph>{t('termsAndConditions.termination.content')}</Paragraph>
      </Section>
      <Section>
        <SectionTitle>{t('termsAndConditions.changes.title')}</SectionTitle>
        <Paragraph>{t('termsAndConditions.changes.content')}</Paragraph>
      </Section>
    </Layout>
  );
};

export default TermsAndConditions;
